import { styled } from 'styled-components';
import colors from '../../styles/colors';

interface CenterProps {
  mobileView: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  overflow: scroll;
  padding-bottom: 100px;
`;

export const ContainerTranslation = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

export const CenterDiv = styled.div<CenterProps>`
  width: ${(props) => (props.mobileView ? '100%' : '50%')};
  height: 100%;
  display: flex;
  justify-content: center;

  padding: ${(props) => (props.mobileView ? '20px' : '0')};

  flex-direction: column;

  .padding-upload {
    padding: 16px;
    width: 100%;
  }

  .row-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;

    svg {
      cursor: pointer;
    }
  }

  .alert-container{
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }
`;
