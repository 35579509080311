import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ENUS from './locales/en-us.json';
import PTBR from './locales/pt-br.json';

const resources = {
  'pt-BR': PTBR,
  'en-US': ENUS,
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  lng: 'en-US',
  fallbackLng: 'pt-BR',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
