import React, { useState } from 'react';
import {
  Heading1Medium,
  Heading4Regular,
  Paragraph,
  ParagraphBold,
  SmallMedium,
  SubtitleRegular,
} from '../../styles/text';
import * as Styled from './styles';
import colors from '../../styles/colors';
import { Tooltip } from 'react-tooltip';
import DropZoneUpload from '../../components/DropZone';
import CallToActionButton from '../../components/Button';
import UploadRowItem from '../../components/UploadRowItem';
import useDetectDevice from '../../helpers/device';
import { apiRequester } from '../../api';
import { getBase64 } from '../../helpers/file';
import SuccessPage from '../SuccessUpload';
import bitGetLogo from '../../assets/logos/bitget.png';
import htxLogo from '../../assets/logos/htx.png';
import kucoinLogo from '../../assets/logos/kucoin.png';
import TextArea from '../../components/TextArea';

import { changeLanguageHandler, getCurrentLanguage } from '../../helpers/lang';
import { useTranslation } from 'react-i18next';

const UploadFilesPage = () => {
  const detect = useDetectDevice();
  const { t } = useTranslation();

  const [fileList, setFileList] = useState<File[]>([]);
  const [sendingFiles, setSendingFiles] = useState(false);
  const [successUpload, setSuccessUpload] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [msg, setMsg] = useState<string>('');

  const uuidByParams = window.location.search.split('=')[1];

  const getCompFromUrl = window.location.href.split('/')[3];

  const getUUIDFromUrl = window.location.href.split('/')[4];

  const getLogoByExchange = () => {
    switch (getCompFromUrl) {
      case 'bitget':
        return bitGetLogo;
      case 'htx':
        return htxLogo;
      case 'kucoin':
        return kucoinLogo;
      default:
        return '';
    }
  };

  const errorsList = [
    {
      code: 'E_USER_NOT_FOUND',
      msg: t('user not found'),
    },
    { code: 'E_USER_IN_ANALYSIS', msg: t('docs already under analysis') },
    { code: 'E_MAX_5_DOCS', msg: t('only five files') },
    { code: 'E_MAX_20MB', msg: t('max 20MB per file') },
  ];

  const uploadFiles = async () => {
    if (uuidByParams) return;

    setSendingFiles(true);

    const fileListBase64 = await Promise.all(
      fileList.map(async (file) => {
        return await getBase64(file);
      })
    );

    try {
      const apiResp = await apiRequester.post(`/compliance/${getUUIDFromUrl}`, {
        docs: fileListBase64,
        additionalInfo: msg,
      });

      if (apiResp.data?.error) {
        const erroMsg = errorsList.find(
          (error) => error.code === apiResp.data.error
        );

        setErrorMsg(erroMsg?.msg || 'Unknown error');
      } else {
        setSuccessUpload(true);
      }
    } catch (error) {
      console.log(error);
    }

    setSendingFiles(false);
  };

  const removeFile = (file: File) => {
    const newList = fileList.filter((item) => item !== file);
    setFileList(newList);
  };

  return (
    <>
      <Styled.ContainerTranslation>
        {getCurrentLanguage() === 'pt-BR' ? (
          <Paragraph
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              changeLanguageHandler('en-US');
            }}
          >
            en-US
          </Paragraph>
        ) : (
          <Paragraph
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              changeLanguageHandler('pt-BR');
            }}
          >
            pt-BR
          </Paragraph>
        )}
      </Styled.ContainerTranslation>

      {!successUpload ? (
        <>
          <Styled.Container>
            <Styled.CenterDiv mobileView={detect.isMobile()}>
              {getLogoByExchange() !== '' && (
                <img
                  style={{
                    width: 122,
                    height: 32,
                    marginTop: 48,
                    objectFit: 'contain',
                  }}
                  alt="logo"
                  src={getLogoByExchange()}
                />
              )}

              <Heading1Medium marginTop={32} marginBottom={24}>
                {t('Additional Document Verification')}
              </Heading1Medium>
              <Paragraph marginBottom={16}>{t('to continue using')}</Paragraph>
              <div className="row-card">
                <Heading4Regular fontWeight={500}>
                  {t('Proof of Financial Capability')}
                </Heading4Regular>

                <div
                  data-tooltip-id="info-details"
                  data-tooltip-html={`${t(
                    'Accepted documents include Income Tax Return'
                  )},<br/> ${t(
                    'Income Statement, Payroll, DECORE, and Deed of Gift of'
                  )}<br/> ${t('Assets and Values')} ${t(
                    '(if applicable)'
                  )}. ${t('For corporate entities')},<br/> ${t(
                    'attach the Balance Sheet from the last fiscal year.'
                  )}`}
                >
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16 24C16 19.5817 19.5817 16 24 16C28.4183 16 32 19.5817 32 24C32 28.4183 28.4183 32 24 32C19.5817 32 16 28.4183 16 24ZM24 14C18.4772 14 14 18.4772 14 24C14 29.5228 18.4772 34 24 34C29.5228 34 34 29.5228 34 24C34 18.4772 29.5228 14 24 14ZM23 20.5C23 19.9477 23.4477 19.5 24 19.5C24.5523 19.5 25 19.9477 25 20.5C25 21.0523 24.5523 21.5 24 21.5C23.4477 21.5 23 21.0523 23 20.5ZM25 23V24V28V29H23V28V24V23H25Z"
                      fill="#272937"
                    />
                  </svg>
                </div>
              </div>

              <div className="alert-container">
                <SmallMedium color={colors.warning}>
                  {t('The documents must cover a period of at least 6 months')}
                </SmallMedium>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 20 17"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 10L9 5L11 5L11 10L9 10Z"
                    fill={colors.warning}
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11 12.5C11 13.0523 10.5523 13.5 10 13.5C9.44772 13.5 9 13.0523 9 12.5C9 11.9477 9.44772 11.5 10 11.5C10.5523 11.5 11 11.9477 11 12.5Z"
                    fill={colors.warning}
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.8481 3.24362C10.4564 2.61696 9.54379 2.61695 9.15212 3.24362L2.76056 13.47C2.34428 14.1361 2.82312 15 3.60856 15H16.3916C17.177 15 17.6558 14.1361 17.2396 13.47L10.8481 3.24362ZM7.45613 2.18361C8.63113 0.303615 11.3691 0.303624 12.5441 2.18363L18.9356 12.41C20.1844 14.4082 18.7479 17 16.3916 17H3.60856C1.25224 17 -0.184282 14.4082 1.06457 12.41L7.45613 2.18361Z"
                    fill={colors.warning}
                  />
                </svg>
              </div>

              <DropZoneUpload fileList={fileList} setFileList={setFileList} />

              {fileList.map((file) => {
                const fileSizeToMb = file.size / 1000000;
                const shortFileName =
                  file.name.length > 20
                    ? file.name.substring(0, 15) + '...'
                    : file.name;
                return (
                  <UploadRowItem
                    fileName={shortFileName}
                    fileSize={fileSizeToMb.toFixed(2).toString()}
                    onRemove={() => {
                      removeFile(file);
                    }}
                    key={file.name}
                  />
                );
              })}

              <Heading4Regular
                marginTop={35}
                marginBottom={15}
                fontWeight={500}
              >
                {t('Additional Information')}
              </Heading4Regular>

              <SubtitleRegular marginBottom={32} color={colors.grayMedium}>
                {t('If there are any additional')}
              </SubtitleRegular>

              <TextArea
                text={msg}
                setText={setMsg}
                placeHolder={t('Type your message.') as string}
              />

              {errorMsg && (
                <ParagraphBold marginTop={8} color={colors.danger}>
                  Error: {errorMsg}
                </ParagraphBold>
              )}

              <div className="padding-upload">
                <CallToActionButton
                  size="large"
                  fullWidth
                  label={t('Send Documents') as string}
                  backgroundColor={colors.black}
                  onClick={uploadFiles}
                  disabled={fileList.length === 0}
                  loading={sendingFiles}
                />
              </div>
            </Styled.CenterDiv>
          </Styled.Container>

          <Tooltip
            id="info-details"
            place="top"
            style={{
              backgroundColor: colors.black,
              opacity: 1,
              borderRadius: 8,
              padding: 12,
              gap: 8,
              zIndex: 9999,
            }}
          />
        </>
      ) : (
        <SuccessPage />
      )}
    </>
  );
};

export default UploadFilesPage;
