import { styled } from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
  position: relative;
  display: flex;

  .container-clean {
    position: relative;
    right: 10px;
    top: 10px;
    z-index: 1;
  }

  textarea {
    width: 100%;
    height: 150px;
    resize: none;
    border: none;
    border-bottom: 2px solid ${colors.grayMedium};
    outline: none;
    padding: 10px;
    font-size: 16px;
    color: ${colors.black};

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }

    &:focus {
      font-family: 'Helvetica Now Display Medium', sans-serif;
    }
  }
`;
