import React from 'react';
import useDetectDevice from '../../helpers/device';
import * as Styled from './styles';
import { BodyRegular, Heading1Medium } from '../../styles/text';
import CallToActionButton from '../../components/Button';
import colors from '../../styles/colors';
import { useTranslation } from 'react-i18next';

const SuccessPage = () => {
  const detect = useDetectDevice();
  const { t } = useTranslation();

  return (
    <Styled.Container>
      <Styled.CenterDiv mobileView={detect.isMobile()}>
        <Heading1Medium marginTop={32} marginBottom={24}>
          {t('Documents Received')}
        </Heading1Medium>

        <BodyRegular>
          {t('Thank you for submitting your documents!')}
        </BodyRegular>

        <BodyRegular marginTop={24}>
         {t('In the meantime, feel free to explore our services and stay tuned for updates in your inbox.')}
        </BodyRegular>

        <BodyRegular marginBottom={40} marginTop={24}>
        {t("Questions? We're here to help.")}
        </BodyRegular>

        <CallToActionButton
          size="small"
          color={colors.black}
          label={t("Customer Support") as string}
          backgroundColor={colors.mainGray}
          onClick={() => {
            window.open('https://help.capitual.com/pt/contact/', '_blank');
          }}
        />
      </Styled.CenterDiv>
    </Styled.Container>
  );
};

export default SuccessPage;
