import * as Styled from './styles';
import CleanSVG from '../../assets/icons/close';

const TextArea = ({
  placeHolder,
  text,
  setText,
}: {
  placeHolder?: string;
  text: string;
  setText: (text: string) => void;
}) => {
  return (
    <Styled.Container>
      <textarea
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
        placeholder={placeHolder}
        name="Text1"
        cols={40}
        rows={10}
      />

      {text.length > 0 && (
        <div
          className="container-clean"
          onClick={() => {
            setText('');
          }}
        >
          <CleanSVG />
        </div>
      )}
    </Styled.Container>
  );
};

export default TextArea;
